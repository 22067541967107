<template>
  <v-flex align-self-center class="sign-up">
    <h1 class="text-center mb-16" v-t="'login.signUp.title'"></h1>

    <v-row v-for="(info, index) in infoList" :key="index" class="text-center">
      <v-col cols="3" class="d-flex">
        <h3>
          <span v-if="info.essential" class="essential">*</span>
          <span>{{ info.text }} </span>
        </h3>
      </v-col>
      <v-col cols="9" class="info-input">
        <v-text-field
          v-if="info.name === 'name'"
          outlined
          single-line
          dense
          :label="`Insert ${info.text}`"
          :rules="[rules[info.essential ? 'required' : 'none']]"
          v-model="userName"></v-text-field>

        <div class="d-flex" v-else-if="info.name === 'number'">
          <div style="width: 270px">
            <v-autocomplete
              dense
              solo
              hide-details
              flat
              outlined
              :items="countries"
              v-model="selectCountry"
              label="Select Country">
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>
          </div>

          <v-text-field
            outlined
            single-line
            dense
            :label="`Insert ${info.text}`"
            :rules="[rules[info.essential ? 'required' : 'none']]"
            class="ml-4"
            v-model="phoneNumber"></v-text-field>
        </div>

        <v-text-field
          v-else-if="info.name === 'email'"
          outlined
          single-line
          dense
          :label="`Insert ${info.text}`"
          :rules="[rules[info.essential ? 'required' : 'none'], rules.email]"
          v-model="email"></v-text-field>

        <v-text-field
          v-else-if="info.name === 'password'"
          outlined
          single-line
          dense
          :label="`Insert ${info.text}`"
          :rules="[rules[info.essential ? 'required' : 'none'], rules.PW1, rules.PW2, rules.PW3, rules.PW4]"
          :type="PWShow ? 'text' : 'password'"
          :append-icon="PWShow ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="PWShow = !PWShow"
          @keyup="$refs.repeatPasswd[0].validate(true)"
          v-model="passwd"></v-text-field>

        <v-text-field
          v-else-if="info.name === 'repeat'"
          outlined
          single-line
          dense
          :label="`Insert ${info.text} Password`"
          :rules="[rules[info.essential ? 'required' : 'none'], rules.repeat]"
          :type="repeatShow ? 'text' : 'password'"
          :append-icon="repeatShow ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="repeatShow = !repeatShow"
          ref="repeatPasswd"
          v-model="repeatPasswd"></v-text-field>
      </v-col>
    </v-row>

    <div class="mt-10 gutter-my-3">
      <!-- <div class="d-flex">
        <div class="fw">
          <x-large-button color="secondary" :outlined="true">
            <h2>* Terms & Conditions</h2>
          </x-large-button>
        </div>
        <x-large-button color="secondary" :outlined="true" :block="false" class="ml-3">
          <h2>OK</h2>
        </x-large-button>
      </div> -->
      <div class="d-flex">
        <v-checkbox color="secondary" v-model="termAgree" />
        <span class="pointer" @click="termAgree = !termAgree">
          I have read and agree to the
          <a href="https://tnc.v-go.io" target="_blank" @click.stop=""> term and conditions </a>
        </span>
      </div>

      <x-large-button color="secondary" @click="signUp">
        <h2>Create Account</h2>
      </x-large-button>
    </div>
  </v-flex>
</template>

<script>
import XLargeButton from "@/components/common/buttons/XLargeButton.vue";
import { hasValue } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";

export default {
  components: { XLargeButton },
  data() {
    return {
      rules: {
        none: () => true,
        required: value => !!value || "Required",
        email: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "User ID has to be in E-mail format.",
        PW1: v =>
          // eslint-disable-next-line no-useless-escape
          /^[a-zA-Z0-9!~@#$%^&*()?+=\/]{8,}$/.test(v) || "Password requires more than eight characters.",
        PW2: v => /[0-9]/g.test(v) || "A Numeric character is needed.",
        PW3: v => /[A-Z]/g.test(v) || "A Capital letter is needed",
        PW4: v => /[a-z]/g.test(v) || "A Small letter is required.",
        repeat: v => this.passwd === v || "Password does not match.",
      },
      infoList: [
        { name: "name", text: "User name", essential: true },
        { name: "number", text: "Phone Number", essential: true },
        { name: "email", text: "User ID (Email)", essential: true },
        { name: "password", text: "Password", essential: true },
        { name: "repeat", text: "Repeat Your Password", essential: true },
      ],
      userName: "",
      selectCountry: "",
      phoneNumber: "",
      email: "",
      passwd: "",
      PWShow: false,
      repeatPasswd: "",
      repeatShow: false,
      termAgree: false,
    };
  },
  methods: {
    signUp() {
      if (this.termAgree === false) return this.$toast.warning("Consent to the Terms and Conditions is mandatory.");

      if (
        ![
          this.userName,
          this.selectCountry,
          this.phoneNumber,
          this.email,
          this.passwd,
          this.$refs.repeatPasswd[0].value,
        ].every(hasValue) ||
        this.rules.email(this.email) !== true ||
        this.rules.PW1(this.passwd) !== true ||
        this.rules.PW2(this.passwd) !== true ||
        this.rules.PW3(this.passwd) !== true ||
        this.rules.PW4(this.passwd) !== true ||
        this.rules.repeat(this.$refs.repeatPasswd[0].value) !== true
      )
        return this.$toast.warning("Please enter all the required items.");

      this.$http
        .post("/signUp", {
          name: this.userName,
          mobilePhone: `${this.selectCountry} ${this.phoneNumber}`,
          email: this.email,
          passwd: this.passwd,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Succeeded in signing up");
            this.$router.push({ name: "emailLogin" });
          } else codeHandler(res.data);
        });
    },
  },
  computed: {
    countries() {
      return this.$store.state.code.common.country.map(c => ({
        text: `${c.itemCd} (${c.itemName})`,
        value: c.itemName,
      }));
    },
  },
};
</script>

<style></style>
